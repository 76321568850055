import { Grid, Typography } from "@material-ui/core"
import React, { ReactElement } from "react"

import BlogPost from "../../../../components/BlogPost"
import { Link } from "../../../../components/Link"
import { SearchResults } from "../../../../components/SearchResults"

interface Props {
  data: any
  location: Location
  pageContext: any
}

export default function Post(props: Props): ReactElement {
  const { location, pageContext } = props
  return (
    <BlogPost
      location={location}
      pageContext={pageContext}
      title="Hobos"
      category="Frühlingtrends 2020"
    >
      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12}>
          <Typography paragraph={true}>
            Soft anstatt kastenförmig, lässig anstatt steif und XXL anstatt XXS ist angesagt. Das
            alles beschreibt die{" "}
            <Link href="/hobos/" underline="always">
              Hobos
            </Link>{" "}
            . Die XXL Hobo Taschen, die lässig über der Schulter getragen werden, sind im Frühling
            2020 im Trend. Sie bieten viel Platz, sind knautschig und haben viel Volumen. 2020 ist
            das Jahr der oversized Volumentaschen. Sie sind nicht langweilig und nebenbei
            gleichzeitig sehr flexibel. Die Hobo-Bags können gut zu einer lockeren Schlaghose
            kombiniert werden und einer schönen Bluse, dazu weiße Sneaker. Der Street-Look ist
            perfekt und gemütlich zu gleich. Wer knautschig nicht mag, der wird die{" "}
            <Link href="/marken/staud/" underline="always">
              Staud
            </Link>{" "}
            <Link href="/handtaschen/" underline="always">
              Handtasche
            </Link>{" "}
            Moon lieben.{" "}
            <Link href="/marken/cluty/" underline="always">
              Cluty
            </Link>{" "}
            bietet dabei eine Vielfalt an XXL-Taschen.{" "}
            <Link href="/marken/usha/" underline="always">
              Usha
            </Link>{" "}
            <Link href="/schultertaschen/" underline="always">
              Schultertaschen
            </Link>{" "}
            sind minimalistisch und ähneln einer Beutelform.
          </Typography>
        </Grid>
        <Grid item={true} xs={12}>
          <SearchResults
            results={[
              {
                href: "https://www.awin1.com/pclick.php?p=24608055099&a=667803&m=14336",
                images: [
                  {
                    alt: "Ein Bild einer Cluty Umhängetasche",
                    src:
                      "https://images.buybags.de/fit-in/1200x1200/filters:fill(white):autojpg()/products/773a95ce161b609f52403ab21ca5b51d.jpg",
                    title: "Cluty Umhängetasche",
                  },
                ],
                name: "Cluty Umhängetasche",
                price: 99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24608166873&a=667803&m=14336",
                images: [
                  {
                    alt: "Ein Bild einer Cluty Shopper",
                    src:
                      "https://images.buybags.de/fit-in/1200x1200/filters:fill(white):autojpg()/products/a174e8cbfca2659c3873ff92907bf78f.jpg",
                    title: "Cluty Shopper",
                  },
                ],
                name: "Cluty Shopper",
                price: 89,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25983496773&a=667803&m=11590",
                images: [
                  {
                    alt: "Ein Bild einer Coccinelle Hobo-Bag in gelb",
                    src:
                      "https://images.buybags.de/fit-in/1200x1200/filters:fill(white):autojpg()/products/5c21644e9b9b55620d4f5f4a5ccd75d5.jpg",
                    title: "Coccinelle Hobo-Bag gelb",
                  },
                ],
                name: "Coccinelle Hobo-Bag",
                price: 295,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25130249989&a=667803&m=11590",
                images: [
                  {
                    alt: "Ein Bild einer Staud Handtasche Moon in rot",
                    src:
                      "https://images.buybags.de/fit-in/1200x1200/filters:fill(white):autojpg()/products/4486af9060bc0d512761bfe0d3ef3742.jpg",
                    title: "Staud Handtasche Moon rot",
                  },
                ],
                name: "Staud Handtasche",
                price: 295,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24410015979&a=667803&m=11926",
                images: [
                  {
                    alt: "Ein Bild einer Fredsbruder Handtasche Clemmy in schwarz",
                    src:
                      "https://images.buybags.de/fit-in/1200x1200/filters:fill(white):autojpg()/products/acb8aae422b460d07affe36f0dc7abe6.jpg",
                    title: "Fredsbruder Handtasche Clemmy Black",
                  },
                ],
                name: "Fredsbruder Handtasche",
                price: 165,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25873226393&a=667803&m=11926",
                images: [
                  {
                    alt: "Ein Bild einer Fredsbruder Handtasche Elle",
                    src:
                      "https://images.buybags.de/fit-in/1200x1200/filters:fill(white):autojpg()/products/b5903a3346dfc3425c1e7c523b155466.jpg",
                    title: "Fredsbruder Handtasche Elle Light Grey",
                  },
                ],
                name: "Fredsbruder Handtasche",
                price: 199,
              },
              {
                href: "https://www.awin1.com/pclick.php?p=25314732227&a=667803&m=14503",
                images: [
                  {
                    alt: "Ein Bild einer SURI FREY Handtasche in cognac für Damen",
                    src:
                      "https://images.buybags.de/fit-in/1200x1200/filters:fill(white):autojpg()/products/34627487594337770389cb95f6d4ce04.jpg",
                    title: "SURI FREY Beutel Penny Handtaschen cognac Damen",
                  },
                ],
                name: "SURI FREY Handtasche",
                price: 62.99,
              },
              {
                href: "https://www.awin1.com/pclick.php?p=24138300509&a=667803&m=14503",
                images: [
                  {
                    alt:
                      "Ein Bild einer Marc O'Polo Handtasche, Nila Hobo Bag L Luxury Suede Handtasche in rosa für Damen",
                    src:
                      "https://images.buybags.de/fit-in/1200x1200/filters:fill(white):autojpg()/products/93e0d4f0cf37f545b2086554eea9ef34.jpg",
                    title:
                      "Marc O'Polo Handtasche Nila Hobo Bag L Luxury Suede Handtaschen rosa Damen",
                  },
                ],
                name: "Marc O'Polo Handtasche",
                price: 145,
              },
              {
                href: "https://www.awin1.com/pclick.php?p=25414230913&a=667803&m=14336",
                images: [
                  {
                    alt: "Ein Bild einer SURI FREY Beuteltasche »Penny« mit Fransen",
                    src:
                      "https://images.buybags.de/fit-in/1200x1200/filters:fill(white):autojpg()/products/8cfe517c0422fad54a6bfea3161d581a.jpg",
                    title: "SURI FREY Beuteltasche »Penny«, Fransen",
                  },
                ],
                name: "SURI FREY Beuteltasche",
                price: 62.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25875754063&a=667803&m=14336",
                images: [
                  {
                    alt: "Ein Bild einer Usha Schultertasche in weiß",
                    src:
                      "https://images.buybags.de/fit-in/1200x1200/filters:fill(white):autojpg()/products/612ca4205afbbca249e5b2a87f0fed03.jpg",
                    title: "Usha Schultertasche",
                  },
                ],
                name: "Usha Schultertasche",
                price: 99.95,
              },
              {
                href: "https://www.awin1.com/pclick.php?p=25879553713&a=667803&m=14336",
                images: [
                  {
                    alt: "Ein Bild einer Usha Schultertasche",
                    src:
                      "https://images.buybags.de/fit-in/1200x1200/filters:fill(white):autojpg()/products/546f9769071c33618e74404234997a1e.jpg",
                    title: "Usha Schultertasche",
                  },
                ],
                name: "Usha Schultertasche",
                price: 99.95,
              },
              {
                href: "https://www.awin1.com/pclick.php?p=24128915415&a=667803&m=14962",
                images: [
                  {
                    alt: "Ein Bild einer Marc O'Polo Hobo-Bag 'Nila'",
                    src:
                      "https://images.buybags.de/fit-in/1200x1200/filters:fill(white):autojpg()/products/61d70066cd3e28215bd509654c6b906c.jpg",
                    title: "Marc O'Polo Hobo-Bag 'Nila'",
                  },
                ],
                name: "Marc O'Polo Hobo-Bag",
                price: 179,
              },
            ]}
          />
        </Grid>
      </Grid>
    </BlogPost>
  )
}
